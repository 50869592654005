import React, { FC } from "react";
import { graphql } from "gatsby";
import { storyblokInit, apiPlugin, useStoryblokState } from "gatsby-source-storyblok";

import Layout from "../components/layout";

storyblokInit({
  accessToken: process.env.GATSBY_SB_PREVIEW_TOKEN,
  use: [apiPlugin],
});

type IndexPageProps = {
  data: any;
};

const IndexPage: FC<IndexPageProps> = ({ data }) => {
  const story = useStoryblokState(data.storyblokEntry)
  return <Layout content={story.content} />
};

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`;

